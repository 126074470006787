<svelte:options tag={null} />
<script lang="ts">

  import { getDevice } from 'rvhelper';
  import { _, addNewMessages, setLocale } from './i18n';
  import { MobileBottomNavigationTranslations } from './translations';

  export let cmsendpoint:string = '';
  export let lang:string = '';
  export let env:string = '';
  export let secondarymenutitle:string = '';
  export let userroles:string = '';
  export let clientstyling:string = '';
  export let clientstylingurl:string = '';

  let userAgent:string = window.navigator.userAgent;
  let isMobile:boolean = (getDevice(userAgent) === 'PC') ? false : true;
  let isLoading:boolean = true;
  let showNavigation:boolean = false;

  let navigationWindowOpened:boolean = false;
  let navigationBarSimple:boolean = false;
  let primaryMenu:Array<Object> = [];
  let primaryMenuSpliced:Array<Object> = [];
  let secondaryMenu:Array<Object> = [];
  let customStylingContainer:HTMLElement;

  Object.keys(MobileBottomNavigationTranslations).forEach((item:any) => {
    addNewMessages(item, MobileBottomNavigationTranslations[item]);
  });

  const navigationWindowToggle = () => {
    navigationWindowOpened = !navigationWindowOpened;
  }

  const createPrimaryMenu = (data) => {
    primaryMenuSpliced.length = 0;
    primaryMenuSpliced = navigationBarSimple ? data.splice(0,5) : data.splice(0,4);
  }

  const getMobileBottomMenu = ():void => {
    isLoading = true;
    let device = getDevice(userAgent)

    let url:URL = new URL(`${cmsendpoint}/${lang}/bottom-mobile-menu?env=${env}`);

    url.searchParams.append('userRoles', userroles);

    if(device){
       if(device === 'iPad' || device === 'iPhone') {
        url.searchParams.append('device', 'ios')
      } else {
        url.searchParams.append('device', 'mtWeb')
      }
    }

    fetch(url.href)
      .then((res: any) => res.json())
      .then((data: any) => {
        isLoading = false;

        // Reset arrays
        primaryMenu.length = 0;
        secondaryMenu.length = 0;

        // Show navigation only if there are items coming from CMS
        showNavigation = data.length > 0;

        // Get items
        data.forEach((item) => {
          if (item.path == '/menu-item') {
            secondaryMenu = item.children;
          } else {
            primaryMenu.push(item);
          }
        });

        // Hide secondary menu if there are no items configured in CMS
        navigationBarSimple = secondaryMenu.length == 0;
        createPrimaryMenu(primaryMenu);
      })
      .then(() => {
        // html menu item is generated after fetching data. after this, set custom styling
        setClientStyling();
        setClientStylingURL();
      })
      .catch((err: any) => {
        isLoading = false;
        console.error(err);
      });
  }

  const triggerNavigation = (url, target?) => {
    window.postMessage({ type: 'NavigateTo', url, target }, window.location.href);
  }

  const setClientStyling = ():void => {
    let sheet = document.createElement('style');
    if(clientstyling && customStylingContainer) {
      sheet.innerHTML = clientstyling;
      customStylingContainer.appendChild(sheet);
    }
  }

  const setClientStylingURL = ():void => {
    let url:URL = new URL(clientstylingurl);
    let cssFile:HTMLElement = document.createElement('style');

    fetch(url.href)
      .then((res:any) => res.text())
      .then((data:any) => {
        cssFile.innerHTML = data

        if (customStylingContainer) {
          setTimeout(() => { customStylingContainer.appendChild(cssFile) }, 1);
        }
      });
  }

  const setActiveLanguage = () => {
    setLocale(lang);
  }

  $: lang && setActiveLanguage();
  $: cmsendpoint && lang && env && userroles && getMobileBottomMenu();

</script>

{#if !isLoading && isMobile && showNavigation}
  <div class="NavigationWrapper" part="NavigationWrapper" bind:this={customStylingContainer}>
    <div class="NavigationPanel" part="NavigationPanel">
      {#if !navigationBarSimple}
        <div class="NavigationWindow {navigationWindowOpened ? 'NavigationWindowOpened' : ''}" part="NavigationWindow {navigationWindowOpened ? 'NavigationWindowOpened' : ''}">
          <span class="ModalCloseBtn" part="ModalCloseBtn" on:click={navigationWindowToggle} role="button">
            <slot name="close" part="ModalCloseBtnSlot">
              <svg class="w-6 h-6" part="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path part="ModalCloseBtnPath" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
            </slot>
          </span>
          <h3 class="NavigationWindowTitle" part="NavigationWindowTitle">{secondarymenutitle || $_('mobileBottomNavigation.secondaryMenuTitle')}</h3>
          <div class="NavigationSecondaryWrapper" part="NavigationSecondaryWrapper">
            {#each secondaryMenu as item}
              <div on:click={() => {!item.attrs?.target ? triggerNavigation(item.path) : triggerNavigation(item.path, item.attrs?.target)}} class="NavigationSecondaryItemAnchor" part="NavigationSecondaryItemAnchor">
                {#if item.img}
                  <div class="NavigationSecondaryItemImgWrapper" part="NavigationSecondaryItemImgWrapper">
                    <img class="NavigationSecondaryItemImg" part="NavigationSecondaryItemImg" src="{item.img}" alt="{item.label}">
                  </div>
                {/if}
                <p class="NavigationSecondaryItemLabel" part="NavigationSecondaryItemLabel">{item.label}</p>
              </div>
            {/each}
          </div>
        </div>
      {/if}

      <div class="NavigationBar {navigationWindowOpened ? 'NavigationBarOpened' : ''} {navigationBarSimple ? 'NavigationBarSimple' : ''}" part="NavigationBar {navigationWindowOpened ? 'NavigationBarOpened' : ''} {navigationBarSimple ? 'NavigationBarSimple' : ''}">
        {#each primaryMenuSpliced as item, i}
          <div on:click={() => {!item.attrs?.target ? triggerNavigation(item.path) : triggerNavigation(item.path, item.attrs?.target)}} class="NavigationPrimaryItemAnchor NavItem{i}" part="NavigationPrimaryItemAnchor NavItem{i}">
            {#if item.img}
              <div class="NavigationPrimaryItemImgWrapper" part="NavigationPrimaryItemImgWrapper">
                <img class="NavigationPrimaryItemImg" part="NavigationPrimaryItemImg" src="{item.img}" alt="{item.label}">
              </div>
            {/if}
            <p class="NavigationPrimaryItemLabel" part="NavigationPrimaryItemLabel">{item.label}</p>
          </div>
        {/each}
        {#if !navigationBarSimple}
          <button class="NavigationButton {navigationWindowOpened ? 'NavigationButtonActive' : ''}" part="NavigationButton {navigationWindowOpened ? 'NavigationButtonActive' : ''}" on:click={navigationWindowToggle}>
            <span part="NavigationButtonSpan">
              <svg part="NavigationButtonSvg" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><defs><style>.a{fill:var(--emfe-w-color-white, #FFFFFF);stroke:var(--emfe-w-color-primary, #D0046C);stroke-linecap:round;stroke-linejoin:round;}.b{stroke:none;}.c{fill:none;}</style></defs><g transform="translate(-150 -514)"><g class="a" part="a" transform="translate(150 514)"><rect class="b" part="b" width="10" height="10" rx="2"/><rect class="c" part="c" x="0.5" y="0.5" width="9" height="9" rx="1.5"/></g><g class="a" part="a" transform="translate(161 514)"><rect class="b" part="b" width="10" height="10" rx="2"/><rect class="c" part="c" x="0.5" y="0.5" width="9" height="9" rx="1.5"/></g><g class="a" part="a" transform="translate(161 525)"><rect class="b" part="b" width="10" height="10" rx="2"/><rect class="c" part="c" x="0.5" y="0.5" width="9" height="9" rx="1.5"/></g><g class="a" part="a" transform="translate(150 525)"><rect class="b" part="b" width="10" height="10" rx="2"/><rect class="c" part="c" x="0.5" y="0.5" width="9" height="9" rx="1.5"/></g></g></svg>
            </span>
          </button>
          {/if}
      </div>
    </div>
  </div>
{/if}

<style lang="scss">

  :host {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  }

  .NavigationWrapper {
    background: var(--emfe-w-color-footer-bg, #212529);
    width: 100%;
    height: 100px;
  }

  .NavigationPanel {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 30px);
    max-width: 450px;
    height: 50px;
    z-index: 30;
  }

  .NavigationWindow {
    display: flex;
    flex-direction: column;
    background: var(--emfe-w-color-white, #FFFFFF);
    position: absolute;
    bottom: 30px;
    left: 0;
    opacity: 0;
    transform: translateY(100%);
    border-radius: 5px 5px 0 0;
    transition: .25s ease;
    width: 100%;
    max-height: 40vh;
    &.NavigationWindowOpened {
      opacity: 1;
      padding-bottom: 40px;
      transform: translateY(5px);
    }

    .ModalCloseBtn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      color: var(--emfe-w-color-secondary, #FD2839);
      background: rgba(255, 255, 255, .1);
      cursor: pointer;
      transition: all 150ms ease-in-out;
      z-index: 1;

      svg {
        width: 24px;
        height: 24px;
        margin: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        background: rgba(255, 255, 255, .2);
      }
    }

    .NavigationWindowTitle {
      padding: 16px 10px;
      margin: 0;
      color: var(--emfe-w-color-contrast, #07072A);
      font-size: 12px;
      flex-grow: 0;
    }

    .NavigationSecondaryWrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 0 10px;
      flex-grow: 1;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .NavigationPrimaryItemAnchor.NavItem0 {
    grid-area: item0;
  }

  .NavigationPrimaryItemAnchor.NavItem1 {
    grid-area: item1;
  }

  .NavigationPrimaryItemAnchor.NavItem2 {
    grid-area: item2;
  }

  .NavigationPrimaryItemAnchor.NavItem3 {
    grid-area: item3;
  }

  .NavigationPrimaryItemAnchor.NavItem4 {
    grid-area: item4;
  }

  .NavigationBar {
    background: var(--emfe-w-color-white, #FFFFFF);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 20% 1fr 1fr;
    grid-template-areas: "item0 item1 . item2 item3";
    height: 50px;
    border-radius: 5px;
    position: relative;
    &.NavigationBarSimple {
      grid-template-columns: repeat(5, 20%);
      grid-template-areas: "item0 item1 item2 item3 item4";
    }
    &.NavigationBarOpened {
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.09);
    }
  }

  .NavigationPrimaryItemAnchor,
  .NavigationSecondaryItemAnchor{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-decoration: none;
    color: var(--emfe-w-color-contrast, #07072A);
    font-size: 9px;
    overflow: hidden;
    white-space: nowrap;
    .NavigationPrimaryItemImg,
    .NavigationSecondaryItemImg {
      width: 20px;
    }
    .NavigationPrimaryItemLabel,
    .NavigationSecondaryItemLabel{
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: center;
    }
  }

  .NavigationSecondaryItemAnchor {
    width: 25%;
    padding: 10px 0;
  }

  .NavigationButton {
    background: var(--emfe-w-color-white, #FFFFFF);
    border: none;
    width: 20%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    svg {
      width: 24px;
      height: 24px;
    }
    &:before {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #F4F4F4;
      content: "";
      height: 0;
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 0;
    }
    &.NavigationButtonActive:before {
      opacity: 1;
      transition: .7s ease;
    }
  }
</style>
